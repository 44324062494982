<script setup>
import Profile from './components/Profile.vue'
import { inject } from '@vercel/analytics';
 
inject();
</script>
<template>
  <div>
    <Profile></Profile>
  </div>
</template>
<style scoped>
</style>