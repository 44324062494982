<script setup>
import { ref } from 'vue'
</script>
<style scoped>
  .card-title  {
    font-size:1.5rem;
    font-weight: 700;
    height: 19.2px;
    line-height: 19.2px;
  }
</style>
<template>
  <div class="main-wrapper">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary fixed-top" id="sideNav">
      <a class="navbar-brand js-scroll-trigger" title="Back to Top" href="#about">
        <span class="d-block d-lg-none">Adrian Chrysanthou</span>
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" title="About Adrian Chrysanthou" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" title="My Projects" href="#projects">Projects</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" title="My Experience" href="#experience">Experience</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" title="My Skills" href="#skills">Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" title="My Education and Certificates" href="#education">Education</a>
          </li>
        </ul>
      </div>
    </nav>

    <div class="container-fluid p-0">
      <section class="resume-section resume-info p-3 p-lg-5 d-flex align-items-center" id="about">
        <div class="w-100">
          <p class="subheading text-primary mb-3">hey, thats me!</p>
          <h1 class="mb-0">Adrian
            <br>Chrysanthou
          </h1>
          <div class="subheading mb-3">Drop Me an Email
            <a href="mailto:adrianchrysanthou@gmail.com">adrianchrysanthou@gmail.com</a>
          </div>
          <p class="lead mb-5">
            Seasoned technology executive with a decade-long track record in leading web application strategies, operational efficiencies, and team leadership. Blending technical expertise with strategic visions to transform digital marketing landscapes and elevate brand engagement. Proficient in adapting to high-paced environments and driving continuous innovation, with a proven ability to communicate effectively and collaborate with stakeholders across various departments. My leadership has resulted in award-winning projects, hiring, leading, and maintaining high-performing teams.
          </p>
          <div class="social-icons">
            <a target="_blank" rel="noopener noreferrer" title="Linkedin" href="https://www.linkedin.com/in/adrian-chrysanthou">
              <i class="devicon-linkedin-plain"></i>
            </a>
            <a target="_blank" rel="noopener noreferrer"  title="Github" href="https://github.com/f00d4tehg0dz">
              <i class="devicon-github-original"></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" title="Twitter" href="https://x.com/_ok_adrian">
              <i class="devicon-twitter-original"></i>
            </a>
            <a target="_blank" rel="noopener noreferrer" title="Adrian Chrysanthou Resume" href="/adrian_chrysanthou_resume-2024.pdf">
              <i class="devicon-readthedocs-original"></i>
            </a>
          </div>
        </div>
      </section>

      <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="projects">
        <div class="w-100">
          <h2 class="mb-3">My Projects</h2>
          <div class="card-deck">
            <div class="card" v-for="proj in projects" :key="proj.description">
              <a target="_blank" rel="noopener noreferrer" :title="proj.name" :href="proj.url">
                <template v-if="proj.image.endsWith('.webm')">
                  <video class="card-img-top" :alt="proj.name" loop autoplay muted>
                    <source :src="`/images/${proj.image}`" type="video/webm">
                    Your browser does not support the video tag.
                  </video>
                </template>
                <template v-else>
                  <img :src="`/images/${proj.image}`" class="card-img-top" :alt="proj.name">
                </template>
              </a>
              <div class="card-body">
                <a target="_blank" rel="noopener noreferrer" :href="proj.url">
                  <h1 class="card-title">{{ proj.name }}</h1>
                </a>
                <p class="card-text">{{ proj.description }}</p>
                <p class="card-type">{{ proj.type }}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="resume-section p-3 p-lg-5 d-flex justify-content-center" id="experience">
        <div class="w-100">
          <h2 class="mb-3">Experience</h2>

          <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-5">
            <div class="resume-content">
              <div class="subheading mb-1">Nielsen</div>
              <p><strong>Director of Web Development</strong></p>
              <p><strong>Principal Architect</strong></p>
              <p><strong>Principal Software Developer</strong></p>
              <p><strong>Senior Software Developer</strong></p>
              <p><strong>Software Developer</strong></p>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">2012 - Present</span>
            </div>
          </div>

          <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div class="resume-content">
              <div class="subheading mb-1">SOE Software</div>
              <p><strong>Flash Developer and Designer</strong></p>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">2010 - 2011</span>
            </div>
          </div>
        </div>
      </section>

      <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="skills">
        <div class="w-100">
          <h2 class="mb-3">Skills</h2>
          <div class="subheading mb-3">Programming Languages & Tools</div>
          <ul class="list-inline dev-icons">
            <li class="list-inline-item">
              <i class="devicon-typescript-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-react-original"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-nextjs-original-wordmark"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-docker-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-nodejs-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-circleci-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-amazonwebservices-plain-wordmark"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-vercel-original"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-php-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-wordpress-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-python-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-postgresql-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-sanity-plain"></i>
            </li>
            <li class="list-inline-item">
              <i class="devicon-solidity-plain"></i>
            </li>
          </ul>

          <div class="subheading mb-3">WorkFlow/Skills</div>
          <ul class="fa-ul mb-0">
            <li>
              <i class="fa-li fa fa-check"></i>
              Focused Innovation & Cutting-Edge Web Stacks
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Data-Driven Decision Making
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Performance Analysis & Improvement
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Leadership & Team Mentoring
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Collaboration with Cross-Functional Teams
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Strategic Planning & Budget Management
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Agile Development & Scrum Methodologies
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Continuous Integration/Continuous Deployment (CI/CD)
            </li>
            <li>
              <i class="fa-li fa fa-check"></i>
              Vendor Management
            </li>
          </ul>
        </div>
      </section>
    
      <section class="resume-section p-3 p-lg-5 d-flex align-items-center" id="education">
        <div class="w-100">
          <h2 class="mb-3">Education</h2>
          <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div class="resume-content">
              <p class="mb-0"><strong>Business Strategy</strong></p>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">2021</span>
            </div>
          </div>
          <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div class="resume-content">
              <p class="mb-0"><strong>Architecting on AWS</strong></p>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">2020</span>
            </div>
          </div>
          <div class="resume-item d-flex flex-column flex-md-row justify-content-between">
            <div class="resume-content">
              <p class="mb-0"><strong>ITIL Foundation</strong></p>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">2017</span>
            </div>
          </div>
          <div class="resume-item d-flex flex-column flex-md-row justify-content-between mb-4">
            <div class="resume-content">
              <p class="mb-0"><strong>Bachelor of Fine Arts</strong></p>
            </div>
            <div class="resume-date text-md-right">
              <span class="text-primary">2006 - 2010</span>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
       projects: [
        {
          image: 'arti.png',
          name: 'Arti Bot',
          description: 'A Discord bot to chat with, generate music and images via text prompts! All powered by StableDiffusion AI Models and ChatGPT 3.5 chat layer ',
          url: 'https://www.artibot.gg/',
          type: 'GenAI, Full Stack Development + Discord Development'
        },
        {
          image: 'aipaints.png',
          name: 'AI Paints for Elgato StreamDeck',
          description: 'Tap the CookieClicker icon and gain cookies. The more cookies you gain the bigger your fame and title will become on Elgato StreamDeck!',
          url: 'https://marketplace.elgato.com/product/ai-paints-959642ad-08dc-4302-9dc0-ba15279f4fff',
          type: 'GenAI, Official Elgato Stream Deck Plugin'
        },
        {
          image: 'ue5-flythrough-google-maps.webm',
          name: 'UE5 Fly-through with 3D GoogleMaps',
          description: 'A conceptual project utilizing user location prompts into ChatGPT, flying the user to the specified x,y coordinates in a 3D Google Maps and Unreal Engine 5 experience.',
          url: 'https://github.com/f00d4tehg0dz/FlyThroughGoogleMaps',
          type: 'ChatGPT, Video Game Development'
        },
        {
          image: 'cookieclickericon.png',
          name: 'Cookie Clicker for Elgato StreamDeck',
          description: 'Tap the CookieClicker icon and gain cookies. The more cookies you gain the bigger your fame and title will become on Elgato StreamDeck!',
          url: 'https://apps.elgato.com/plugins/com.f00d4tehg0dz.cookieclicker',
          type: 'Official Elgato Stream Deck Plugin'
        },
        {
          image: 'curiousaxolotl.png',
          name: 'Curious Axolotl',
          description: 'Curious Axolotl is a collection of NFTs - unique digital collectibles, crawling along the seafloor of the Ethereum Blockchain.',
          url: 'https://www.curiousaxolotlnft.com/',
          type: 'Ethereum + Full Stack Development + Solidity + Python'
        },
        {
          image: '45chaos.png',
          name: '45Chaos',
          description: 'Co-Founder and original Architect for 45Chaos.com. What started as a simple website in partnership with Adrienne to catalog every mooch in the current US Presidency turned into a large archival endeavour',
          url: 'https://45chaos.com',
          type: 'Full Stack Development'
        },
        {
          image: 'wheelhauslogo.png',
          name: 'The WheelHaus',
          description: 'Built for Roosterteeth.com Funhaus team. The Wheelhaus utilizes the SteamWebAPI to pull in your Steam Library games and lets you spin the wheel to find a game to play!',
          url: 'https://thewheelhaus.com',
          type: 'Full Stack Development'
        },
        {
          image: 'brdrop.png',
          name: 'Battle Royale Drop Generator',
          description: 'Built as both a website and as Discord bot for gamers to find the next spot to drop in some of their favorite Battle Royale games like Fortnite, Apex Legends and PUBG.  ',
          url: 'https://brdrop.com',
          type: 'Full Stack Development + Discord Development'
        },
        {
          image: 'wheelhaus-discordbot.png',
          name: 'The WheelHaus Discord Bot',
          description: 'A Discord bot that lets you spin the wheel to find a game to play inside Discord!',
          url: 'https://github.com/f00d4tehg0dz/TheWheelhaus-Discord-Bot',
          type: 'Discord Development'
        },
        {
          image: 'bob-ross-paints.webm',
          name: 'Bob Ross Paints',
          description: 'Randomly paints a picture from a Bob Ross painting on your Stream Deck',
          url: 'https://github.com/f00d4tehg0dz/elgato-streamdeck-bob-ross-paints',
          type: 'Official Elgato Stream Deck Plugin'
        },
        {
          image: 'teslafielgato.png',
          name: 'TeslaFi Elgato StreamDeck',
          description: 'View your Tesla Data using TeslaFi API on Elgato StreamDeck',
          url: 'https://apps.elgato.com/plugins/com.f00d4tehg0dz.teslafi',
          type: 'Official Elgato Stream Deck Plugin'
        },
        {
          image: 'teslfimm.png',
          name: 'Magic Mirror - TeslaFi',
          description: 'It monitors a your Tesla Stats, such as Battery Level, Temperature, Lock status and much more!',
          url: 'https://github.com/f00d4tehg0dz/starcitizen-server-status-plugin-for-elgato-streamdeck',
          type: 'Full Stack Development + Magic Mirror Development'
        },
        {
          image: 'endless-ski.png',
          name: 'Endless Ski',
          description: 'Relive the Classic 90s era game Ski Free in an re-imagined fun, fast paced skiing game. Dont forget to avoid the Yeti! Built in Unreal Engine 5',
          url: 'https://store.steampowered.com/app/1125800/Endless_Ski/',
          type: 'Video Game Development'
        },
        {
          image: 'asteroidsunrealengine.png',
          name: 'Asteroids Unreal Engine - Skill-Share',
          description: 'Developed with Unreal Engine 4 and taught on SkillShare',
          url: 'https://github.com/f00d4tehg0dz/Skillshare-Asteroids---Blank',
          type: 'Video Game Development'
        },
        {
          image: 'flappybird3d.png',
          name: 'FlappyBird3D',
          description: 'Re-imagined Flappy Bird but in 3D. Developed with Unreal Engine 4',
          url: 'https://github.com/f00d4tehg0dz/FlappyBird3D',
          type: 'Video Game Development'
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelectorAll('a.js-scroll-trigger[href*="#"]:not([href="#"])').forEach(anchor => {
        anchor.addEventListener('click', function (event) {
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            let target = document.querySelector(this.hash);
            target = target ? target : document.querySelector('[name=' + this.hash.slice(1) + ']');
            if (target) {
              window.scrollTo({
                top: target.offsetTop,
                behavior: 'smooth'
              });
              event.preventDefault();
           
              const navbarCollapse = document.querySelector('.navbar-collapse');
              if (navbarCollapse && navbarCollapse.classList.contains('show')) {
                navbarCollapse.classList.remove('show');
              }
            }
          }
        });
      });

      const sections = document.querySelectorAll('section');
      const navLinks = document.querySelectorAll('#sideNav a');

      window.addEventListener('scroll', () => {
        let currentSection = '';

        sections.forEach(section => {
          const sectionTop = section.offsetTop;
          if (pageYOffset >= sectionTop - 50) {
            currentSection = section.getAttribute('id');
          }
        });

        navLinks.forEach(link => {
          link.classList.remove('active');
          if (link.getAttribute('href').includes(currentSection)) {
            link.classList.add('active');
          }
        });
      });
      const navbarToggler = document.querySelector('.navbar-toggler');
      if (navbarToggler) {
        navbarToggler.addEventListener('click', () => {
          const navbarCollapse = document.querySelector('.navbar-collapse');
          if (navbarCollapse) {
            navbarCollapse.classList.toggle('show');
          }
        });
      }
    });
  }
}
</script>
